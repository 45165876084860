<div class="card panel-flat-color primary-panel idp-panel">
    <div class="panel-flat-header document-processing-quick-search-header">
        <h3>Detected Values</h3>
    </div>
    <div class="panel-flat-body">
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
                <label class="mb-0 me-2">Confidence Level: </label>
                <span>{{confidenceLevel}}</span>
            </div>
            <button class="flat-button"
                    (click)="seeDetails()">
                See Details <i class="fa fa-external-link"></i>
            </button>
        </div>
    </div>
</div>
