import { NgModule } from '@angular/core';
import { Ng2StateDeclaration, UIRouter, UIRouterModule } from '@uirouter/angular';
import { Visualizer } from '@uirouter/visualizer';
import { AboutComponent } from './Admin/About/about.component';
import { AdminComponent } from './Admin/admin.component';
import { ManageAPIKeysComponent } from './Admin/ManageAPIKeys/manage-api-keys.component';
import { AgentsPageComponent } from './Agents/agents.page.component';
import {
    AppealRecommendationCommandCenterComponent
} from './Appeal-Recommendation/Appeal-Recommendation-Command-Center/appealRecommendationCommandCenter.component';
import { BulkImportAssessmentsComponent } from './Bulk-Import/Assessments/bulk.import.assessments.component';
import { BulkImportSitesComponent, ImportType } from './Bulk-Import/Sites/bulk.import.sites.component';
import { InstanceRights, Roles } from './Common/Permissions/restrict.service';
import { EntityImportListComponent } from './Compliance/Entity-Import/Import-List/importList.component';
import { FactorTableListComponent } from './Compliance/Factor-Table/Factor-Table-List/factorTableList.component';
import { FormListComponent } from './Compliance/Form/Form-List/formList.component';
import {
    LongRunningProcessPageComponent
} from './Compliance/Long-Running-Process/Long-Running-Process-Page/longRunningProcessPage.component';
import {
    StateJurisdictionCommandCenterComponent
} from './Compliance/State-And-Jurisdiction/stateJurisdiction.component';
import { ConsultingEngagementsComponent } from './Consulting/consultingEngagements.component';
import { ContactListPageComponent } from './Contact/Page/contactListPage.component';
import { ProcessListComponent } from './Diagnostics/Database/Process-List/processList.component';
import { DiagnosticsComponent } from './Diagnostics/diagnostics.component';
import { ErrorLogListComponent } from './Diagnostics/Error-Log/Error-Log-List/errorLogList.component';
import { QueryInfoListComponent } from './Diagnostics/Query-Info/Query-Info-List/queryInfoList.component';
import {
    DocumentIntakeLicensedClientListComponent
} from './Documents/Document-Intake-Licensed-Clients/Document-Intake-Licensed-Client-List/documentIntakeLicensedClientList.component';
import { InstanceInfoPanelComponent } from './Entity/Instance/Info/instanceInfoPanel.component';
import { ManageInvitationsComponent } from './Entity/Instance/manageInvitations.component';
import { LandingSelectorComponent } from './Landing/landing.selector.component';
import { SavedActionViewComponent } from './Navigation/Saved-AV/savedActionView.component';
import { SavedReportComponent } from './Navigation/Saved-Report/savedReport.component';
import { TopNavService } from './Navigation/Top-Nav/topNav.service';
import { SaveTabContentComponent } from './Navigation/User-Save/Save-Tab-Content/saveTabContent.component';
import { SavedSmartComponent } from './Navigation/Saved-Smart/savedSmart.component';
import { SearchCategoryType, SearchPageType, UserSaveService } from './Navigation/User-Save/userSave.service';
import { PaymentBatchCommandCenterComponent } from './Payment-Batch/Command-Center/paymentBatch.component';
import { PropertyCharacteristicsComponent } from './Property-Characteristics/property.characteristics.component';
import { RolesListComponent } from './Role/roles.list.component';
import { SupportInstanceListComponent } from './Support-Instances/Support-Instance-List/supportInstanceList.component';
import {
    ActionViewOutputDefaultsComponent
} from './Task/ActionView/Action-View-Output-Defaults/actionViewOutputDefaults.component';
import { TaxRateCommandCenterComponent } from './Tax-Rate/Command-Center/tax.rate.command.center.component';
import { TeamsPageComponent } from './Team/teams.page.component';
import { UserGroupListComponent } from './User-Group/User-Group-List/userGroupList.component';
import { ValuationTemplatesComponent } from './Valuation/Templates/valuationTemplates.component';

export function config(uiRouter: UIRouter) {
    // Uncomment to enable the router visualizer for debugging
    // window['visualizer'] && window['visualizer'].dispose();
    // window['visualizer'] = uiRouter.plugin(Visualizer);
    uiRouter.urlService.rules.initial({ state: 'home' });
    uiRouter.urlService.rules.otherwise({ state: 'home' });
}

export const STATES: Ng2StateDeclaration[] = [
    {
        name: 'home',
        url: '/home',
        component: LandingSelectorComponent
    },
    {
        name: 'savedSmart',
        url: '/saved/smart',
        component: SavedSmartComponent,
        redirectTo: 'savedSmart.system'
    },
    {
        name: 'savedSmart.system',
        url: '/system',
        component: SaveTabContentComponent,
        resolve: [
            { token: 'userPageConfig', deps: [UserSaveService], resolveFn: async (service: UserSaveService) => {
                const page = service.getSavePage(SearchPageType.Smart);
                if (page[SearchCategoryType.System].length === 0) {
                    await service.updateList(SearchPageType.Smart);
                } else {
                    service.updateList(SearchPageType.Smart);
                }
                page.currentSelectedFilter = SearchCategoryType.System;
                return page;
            } }
        ]
    },
    {
        name: 'savedSmart.user',
        url: '/user',
        component: SaveTabContentComponent,
        resolve: [
            { token: 'userPageConfig', deps: [UserSaveService], resolveFn: async (service: UserSaveService) => {
                const page = service.getSavePage(SearchPageType.Smart);
                if (page[SearchCategoryType.User].length === 0) {
                    await service.updateList(SearchPageType.Smart);
                } else {
                    service.updateList(SearchPageType.Smart);
                }
                page.currentSelectedFilter = SearchCategoryType.User;
                return page;
            } }
        ]
    },
    {
        name: 'savedSmart.favorite',
        url: '/favorite',
        component: SaveTabContentComponent,
        resolve: [
            { token: 'userPageConfig', deps: [UserSaveService], resolveFn: async (service: UserSaveService) => {
                const page = service.getSavePage(SearchPageType.Smart);
                if (page[SearchCategoryType.Favorite].length === 0) {
                    await service.updateList(SearchPageType.Smart);
                } else {
                    service.updateList(SearchPageType.Smart);
                }
                page.currentSelectedFilter = SearchCategoryType.Favorite;
                return page;
            } }
        ]
    },
    {
        name: 'savedActionView',
        url: '/saved/action-view',
        component: SavedActionViewComponent,
        redirectTo: 'savedActionView.system'
    },
    {
        name: 'savedActionView.system',
        url: '/system',
        component: SaveTabContentComponent,
        resolve: [
            { token: 'userPageConfig', deps: [UserSaveService], resolveFn: async (service: UserSaveService) => {
                    const page = service.getSavePage(SearchPageType.AV);
                    if (page[SearchCategoryType.System].length === 0) {
                        await service.updateList(SearchPageType.AV);
                    } else {
                        service.updateList(SearchPageType.AV);
                    }
                    page.currentSelectedFilter = SearchCategoryType.System;
                    return page;
                } }
        ]
    },
    {
        name: 'savedActionView.user',
        url: '/user',
        component: SaveTabContentComponent,
        resolve: [
            { token: 'userPageConfig', deps: [UserSaveService], resolveFn: async (service: UserSaveService) => {
                    const page = service.getSavePage(SearchPageType.AV);
                    if (page[SearchCategoryType.User].length === 0) {
                        await service.updateList(SearchPageType.AV);
                    } else {
                        service.updateList(SearchPageType.AV);
                    }
                    page.currentSelectedFilter = SearchCategoryType.User;
                    return page;
                } }
        ]
    },
    {
        name: 'savedActionView.favorite',
        url: '/favorite',
        component: SaveTabContentComponent,
        resolve: [
            { token: 'userPageConfig', deps: [UserSaveService], resolveFn: async (service: UserSaveService) => {
                const page = service.getSavePage(SearchPageType.AV);
                if (page[SearchCategoryType.Favorite].length === 0) {
                    await service.updateList(SearchPageType.AV);
                } else {
                    service.updateList(SearchPageType.AV);
                }
                page.currentSelectedFilter = SearchCategoryType.Favorite;
                return page;
            } }
        ]
    },
    {
        name: 'savedReport',
        url: '/saved/report',
        component: SavedReportComponent,
        redirectTo: 'savedReport.system'
    },
    {
        name: 'savedReport.system',
        url: '/system',
        component: SaveTabContentComponent,
        resolve: [
            { token: 'userPageConfig', deps: [UserSaveService], resolveFn: async (service: UserSaveService) => {
                    const page = service.getSavePage(SearchPageType.Report);
                    if (page[SearchCategoryType.System].length === 0) {
                        await service.updateList(SearchPageType.Report);
                    } else {
                        service.updateList(SearchPageType.Report);
                    }
                    page.currentSelectedFilter = SearchCategoryType.System;
                    return page;
                } }
        ]
    },
    {
        name: 'savedReport.user',
        url: '/user',
        component: SaveTabContentComponent,
        resolve: [
            { token: 'userPageConfig', deps: [UserSaveService], resolveFn: async (service: UserSaveService) => {
                    const page = service.getSavePage(SearchPageType.Report);
                    if (page[SearchCategoryType.User].length === 0) {
                        await service.updateList(SearchPageType.Report);
                    } else {
                        service.updateList(SearchPageType.Report);
                    }
                    page.currentSelectedFilter = SearchCategoryType.User;
                    return page;
                } }
        ]
    },
    {
        name: 'manage',
        url: '/manage',
        component: AdminComponent,
        redirectTo: (trans) => {
            const topNavService = trans.injector().get(TopNavService) as TopNavService;
            if (topNavService.roles().hasContactRoles) {
                return 'manage.contacts';
            }
        },
        resolve: []
    },
    {
        name: 'manage.contacts',
        url: '/contacts',
        component: ContactListPageComponent,
        data: {
            instanceRights: [InstanceRights.CONTACTVIEW, InstanceRights.CONTACTEDIT]
        },
        resolve: []
    },
    {
        name: 'manage.agents',
        url: '/agents',
        component: AgentsPageComponent,
        resolve: []
    },
    {
        name: 'manage.propertyCharacteristics',
        url: '/property-characteristics',
        component: PropertyCharacteristicsComponent,
        data: {
            roles: [Roles.PROPCHARVIEW, Roles.PROPCHAREDIT]
        },
        resolve: []
    },
    {
        name: 'manage.roles',
        url: '/roles',
        component: RolesListComponent,
        resolve: [],
        data: {
            roles: [Roles.CONTACTROLEVIEW]
        }
    },
    {
        name: 'manage.userGroups',
        url: '/user-group',
        component: UserGroupListComponent,
        resolve: [],
        data: {
            instanceRights: [
                InstanceRights.MANAGEUSERSETUP
            ]
        }
    },
    {
        name: 'manage.manageApiKeys',
        url: '/manage-API-keys',
        component: ManageAPIKeysComponent,
        resolve: []
    },
    {
        name: 'manage.manageInstanceSettings',
        url: '/instance',
        component: InstanceInfoPanelComponent,
        resolve: [],
        data: {
            instanceRights: [
                InstanceRights.MANAGECONSULTINGENGAGEMENT
            ]
        },
    },
    {
        name: 'manage.teams',
        url: '/teams',
        component: TeamsPageComponent,
        resolve: []
    },
    {
        name: 'manage.importAssessments',
        url: '/import/assessments',
        component: BulkImportAssessmentsComponent,
        resolve: []
    },
    {
        name: 'manage.importSites',
        url: '/import/sites',
        component: BulkImportSitesComponent,
        resolve: [
            { token: 'type', resolveFn: () => ImportType.Site }
        ]
    },
    {
        name: 'manage.importParcels',
        url: '/import/parcels',
        component: BulkImportSitesComponent,
        resolve: [
            { token: 'type', resolveFn: () => ImportType.Parcel }
        ]
    },
    {
        name: 'manage.factorTables',
        url: '/factor-tables',
        component: FactorTableListComponent,
        resolve: [],
        data: {
            roles: [
                Roles.COMPLIANCESETUPSVIEW,
                Roles.COMPLIANCESETUPSEDIT
            ]
        }
    },
    {
        name: 'manage.forms',
        url: '/forms',
        component: FormListComponent,
        resolve: [],
        data: {
            roles: [
                Roles.COMPLIANCESETUPSVIEW,
                Roles.COMPLIANCESETUPSEDIT
            ]
        }
    },
    {
        name: 'manage.valuationTemplates',
        url: '/valuation-templates',
        component: ValuationTemplatesComponent,
        resolve: [],
        data: {
            instanceRights: [
                InstanceRights.VALUATIONTEMPLATEEDIT
            ]
        }
    },
    {
        name: 'manage.paymentBatchCommandCenter',
        url: '/payment-batch-command-center',
        component: PaymentBatchCommandCenterComponent,
        resolve: [],
        data: {
            roles: [Roles.RYANPRIVATEITEMSVIEW, Roles.RYANPRIVATEITEMSEDIT]
        }
    },
    {
        name: 'manage.appealRecommendationCommandCenter',
        url: '/appeal-recommendation-command-center',
        component: AppealRecommendationCommandCenterComponent,
        resolve: []
    },
    {
        name: 'manage.taxRateCommandCenter',
        url: '/tax-rate-command-center',
        component: TaxRateCommandCenterComponent,
        resolve: [],
        data: {
            roles: [Roles.RYANPRIVATEITEMSVIEW, Roles.RYANPRIVATEITEMSEDIT]
        }
    },
    {
        name: 'manage.stateJurisdictionCommandCenter',
        url: '/state-jurisdiction-command-center',
        component: StateJurisdictionCommandCenterComponent,
        resolve: [],
        data: {
            roles: [Roles.RYANPRIVATEITEMSVIEW, Roles.RYANPRIVATEITEMSEDIT]
        }
    },
    {
        name: 'manage.dataImports',
        url: '/import',
        component: EntityImportListComponent,
        resolve: []
    },
    {
        name: 'manage.manageInstances',
        url: '/manage-instances',
        component: ManageInvitationsComponent,
        resolve: []
    },
    {
        name: 'manage.manageSupportInstances',
        url: '/manage-support-instances',
        component: SupportInstanceListComponent,
        resolve: []
    },
    {
        name: 'manage.manageConsultingEngagements',
        url: '/manage-consulting-engagements',
        component: ConsultingEngagementsComponent,
        resolve: [],
        data: {
            instanceRights: [
                InstanceRights.MANAGECONSULTINGENGAGEMENT
            ]
        }
    },
    {
        name: 'manage.manageLicensedClientDocuments',
        url: '/manage-licensed-client-documents',
        component: DocumentIntakeLicensedClientListComponent,
        resolve: []
    },
    {
        name: 'manage.actionViewOutputFields',
        url: '/action-view-output-fields',
        component: ActionViewOutputDefaultsComponent,
        resolve: []
    },
    {
        name: 'manage.about',
        url: '/about',
        component: AboutComponent,
        resolve: []
    },
    {
        name: 'diagnostic',
        url: '/diagnostic',
        component: DiagnosticsComponent,
        resolve: []
    },
    {
        name: 'diagnostic.longRunningProcess',
        url: '/long-running-process',
        component: LongRunningProcessPageComponent,
        resolve: []
    },
    {
        name: 'diagnostic.errorLog',
        url: '/error-log',
        component: ErrorLogListComponent,
        resolve: []
    },
    {
        name: 'diagnostic.queryInfo',
        url: '/query-info',
        component: QueryInfoListComponent,
        resolve: []
    },
    {
        name: 'diagnostic.database',
        url: '/database',
        component: ProcessListComponent,
        resolve: []
    }
];

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: STATES, config: config })
    ]
})
export class Ng2RoutesModule {}
