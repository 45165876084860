import { IdpValue } from '../../Search/Quick/Criteria/quickCriteria.model';

(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('DocumentProcessingController', DocumentProcessingController);

	DocumentProcessingController.$inject = [
		'$q',
		'$sce',
		'$stateParams',
		'$rootScope',
		'$scope',
        '$state',
		'DocumentService',
		'sdAttachmentService',
		'popUpService',
		'$uibModal',
		'processingService',
		'toastr',
		'sd.Navigation.Service',
		'EntityTypeIDs',
		'DocumentExceptionActions',
		'messageBoxService',
        'MessageBoxButtons',
        'MessageBoxResult',
		'PropertyTypes',
		'ProcessingExceptionStatusText',
        'actionViewPersistenceService',
        'EntityTypeNames',
        'taxRateSetupModalLaunchService',
        'featureFlagsService',
        'RyanInstanceId'
	];

	function DocumentProcessingController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit(
		$q,
		$sce,
		$stateParams,
		$rootScope,
		$scope,
		$state,
		documentService,
		attachmentService,
		popUpService,
		$uibModal,
		processingService,
		toastr,
		navigationService,
		EntityTypeIDs,
		DocumentExceptionActions,
		messageBoxService,
        MessageBoxButtons,
        MessageBoxResult,
		PropertyTypes,
		ProcessingExceptionStatusText,
        actionViewPersistenceService,
        EntityTypeNames,
        taxRateSetupModalLaunchService,
        featureFlagsService,
        RyanInstanceId
	) {
		var vm = this;
		var completedItemCount = 0;
		var newDocumentTypeID = null;
		var navigateWarning = 'Entity is in process. Are you sure you want to leave?';
		var cleanParcelData = null;
		const ExceptionStatusIDs = {
			RATES_REQUESTED: 5
		};

		vm.initializing = false;
		vm.EntityTypeNames = EntityTypeNames;
		vm.ProcessingExceptionStatusText = ProcessingExceptionStatusText;
		vm.showCriteria = true;
		vm.showOverlay = false;
		vm.isDirty = false;
		vm.warnOnEntityReload = false;
		vm.validationMessage = '';
		vm.dismissModalValidationOpen = false;
		vm.saveEntityValidationOpen = false;
		vm.completeValidationOpen = false;
		vm.loadingRevisionData = false;
		vm.searchTimestamp = null;
        vm.isQCItem = false;
		vm.editModeEntityPanel = false;
		vm.addressPopoverData = {};
		vm.isPopoutDynamic = false;
        vm.hasIdpFeature = false;
        vm.ryanInstanceId = RyanInstanceId;

		vm.warnOnEntityReloadChanged = function (newValue) {
			vm.warnOnEntityReload = newValue;
		};

        vm.forceReloadEntity = function (includeParcel) {
            return $q(function (resolve) {
                vm.loadingRevisionData = true;
                // Reset the entity edit panel first, then reload the necessary data
                var originalSelectedEntity = vm.selectedEntity;
                vm.selectedEntity = null;
                if (includeParcel) {
                    vm.currentRow.intakeDocumentInfo.parcelChanges = null;
                }
                var originalDataEntryObject = JSON.parse(vm.dataEntryJSONHandler.retrieveDataEntryJSON());
                originalDataEntryObject.resetOnLoad = true;
                vm.currentRow.intakeDocumentInfo.intakeItem.entryJSON = JSON.stringify(originalDataEntryObject);
                var wasOriginallyChanged = vm.associatedEntityChanged;
                vm.editModeEntityPanel = false;
                setTimeout(function () {
                    vm.associatedEntityChanged = false;
                    if (wasOriginallyChanged) {
                        vm.setDirty(true);
                    }
                    if (includeParcel) {
                        getEntityTree(originalSelectedEntity.entityTypeId, originalSelectedEntity.entityId).then(function () {
                            resolve();
                        });
                    }
                    else {
                        vm.selectedEntity = {
                            entityId: originalSelectedEntity.entityId,
                            entityTypeId: originalSelectedEntity.entityTypeId
                        };
                        resolve();
                    }
                }, 1);
            });
        };

		vm.reloadEntity = function () {
            if (vm.warnOnEntityReload || (vm.currentRow && vm.currentRow.intakeDocumentInfo && vm.currentRow.intakeDocumentInfo.parcelChanges)) {
                return messageBoxService.open({
                    message: 'Changes have been made to this entity; if you reload now, those changes will be undone. Are you sure?',
                    buttons: MessageBoxButtons.OKCancel
                }).then(function (result) {
                    if (result === MessageBoxResult.OK) {
                        vm.setDirty(true);
                        return vm.forceReloadEntity(true).then(function () { });
                    }
                    else {
                        return $q.resolve();
                    }
                });
            }
            else {
                return vm.forceReloadEntity(true).then(function () { });
            }
        };

        vm.onFirstEncounterSaved = function (result) {
            vm.setDirty(true);
            if (!result.updateVersion) {
                return vm.forceReloadEntity(false);
            }
            return $q.resolve();
        };


		// The entity edit panel needs to be able to set up a function that the document processing
		// controller can call to get data entry JSON. We're creating the dataEntryJSONHandler object to
		// set that up; when the entity edit panel loads, it should set the retrieveDataEntryJSON property
		// to a function that will get the current dataEntryJSON value.
		vm.dataEntryJSONHandler = {
			retrieveDataEntryJSON: null
		};

		// Same kind of deal with the validation handler; document processing needs to reach into the
		// entityEditComponent and call it's validation function
		vm.validationHandler = {
			validate: null
		};

		vm.setDirty = function (isDirty) {
			if (vm.isDirty !== isDirty) {
				vm.isDirty = isDirty;
				if (isDirty) {
					navigationService.enableNavWarning(navigateWarning);
				} else {
					navigationService.disableNavWarning();
				}
			}
		};

		activate();

		$scope.$on('$destroy', function () {
			navigationService.disableNavWarning();
		});

		$scope.$on('angular-resizable.resizeStart', function () {
			vm.showOverlay = true;
		});

		$scope.$on('angular-resizable.resizeEnd', function () {
			vm.showOverlay = false;
		});

        // If the attachment dialog is opened, the popout preview will show attachments potentially; when the modal
        // is closed, navigate it back to the current processing document if appropriate
		var attachmentClosingEvent = $rootScope.$on('attachment.modal.closing', function () {
            var globalAttachmentsPopUpRef = popUpService.getByAliasGroup(popUpService.StaticAliases.Attachment)[0];
            if (globalAttachmentsPopUpRef && vm.currentRow && vm.currentRow.intakeDocumentInfo) {
                notifyChildWindow(globalAttachmentsPopUpRef, vm.currentRow.intakeDocumentInfo);
            }
		});

        $scope.$on('$destroy', function () {
            attachmentClosingEvent();
        });


		////////////////

		vm.onSkipClick = function () {
			if (vm.isDirty) {
				messageBoxService.confirm(navigateWarning).then(function () {
                    vm.disableSkip = true;
					vm.openNextDocument().then(function () {
                        vm.disableSkip = false;
                    });
				});
			}
			else {
                vm.disableSkip = true;
				vm.openNextDocument().then(function () {
                    vm.disableSkip = false;
                });
			}
		};

		vm.openNextDocument = function () {
			vm.setDirty(false);
			newDocumentTypeID = null;
			if (vm.currentRowIndex >= vm.rows.length - 1) {
                messageBoxService.open({
                    title: 'SUCCESS',
                    message: 'You have completed or skipped all documents. Number of documents processed: '+completedItemCount +'. Click ok to return',
                    buttons: MessageBoxButtons.OK}).then(function() {
                        navigationService.disableNavWarning();
                        if ($state.params.parcelID) {
                            $state.go('parcelRedirect', { entityID: $state.params.parcelID });
                        }
                        else {
                            $state.go('actionview', actionViewPersistenceService.routeParams);
                        }
                });
				return $q.resolve(null);
			}

			vm.currentRowIndex++;
			vm.selectedEntity = null;

			// just in case if data is not here
			if (!vm.rows[vm.currentRowIndex].intakeDocumentInfo) {
				getRowData(vm.currentRowIndex, true).then(function () {
					var globalAttachmentsPopUpRef = popUpService.getByAliasGroup(popUpService.StaticAliases.Attachment)[0];
					globalAttachmentsPopUpRef && vm.isPopoutDynamic && notifyChildWindow(globalAttachmentsPopUpRef, vm.currentRow.intakeDocumentInfo);
				});
			} else {
				vm.currentRow = vm.rows[vm.currentRowIndex];
				var globalAttachmentsPopUpRef = popUpService.getByAliasGroup(popUpService.StaticAliases.Attachment)[0];
				globalAttachmentsPopUpRef && vm.isPopoutDynamic && notifyChildWindow(globalAttachmentsPopUpRef, vm.currentRow.intakeDocumentInfo);
			}

			vm.entityIsMapped = vm.currentRow.intakeDocumentInfo.intakeItem.entityID !== null;
			vm.associatedEntityMissing = vm.currentRow.intakeDocumentInfo.associatedEntityMissing;
			vm.associatedEntityChanged = vm.currentRow.intakeDocumentInfo.associatedEntityChanged;
			vm.documentTypeChanged();

			if (vm.entityIsMapped) {
			    if (!vm.associatedEntityMissing) {
			        getEntityTree(vm.currentRow.intakeDocumentInfo.intakeItem.entityTypeID, vm.currentRow.intakeDocumentInfo.intakeItem.entityID);
			    }
				vm.showCriteria = false;
			} else {
				vm.entityDetails = undefined;
				vm.showCriteria = true;
			}

			$scope.$broadcast("changeProcessingDocument");

			// load next one
			return getRowData(vm.currentRowIndex + 1, false);
		}

		vm.popoutPreview = function (isDynamic) {
			var globalAttachmentsPopUpRef = popUpService.getByAliasGroup(popUpService.StaticAliases.Attachment)[0];
			if (globalAttachmentsPopUpRef && globalAttachmentsPopUpRef.isOpen()) {
				notifyChildWindow(globalAttachmentsPopUpRef, vm.rows[vm.currentRowIndex].intakeDocumentInfo);
			} else {
				popUpService.openAttachmentPreview(popUpService.StaticAliases.Attachment, globalAttachmentsPopUpRef).then(function(popUpRef) {
					notifyChildWindow(popUpRef, vm.rows[vm.currentRowIndex].intakeDocumentInfo);
				}, function() {});
			}

			vm.isPopoutDynamic = isDynamic;
		};

		function checkValidationFunction(validator) {
			// wk-2511- Dismiss and save buttons not working until an entity has been selected
			var result = null;
			if (validator) {
				result = validator;
			}
			else {
				result = function (callback) {
					callback(true, '');
					return;
				};
			}
			return result;
		}

		vm.openDismissModal = function () {

			checkValidationFunction(vm.validationHandler.validate)(function (isValid, message) {
				vm.dismissModalValidationOpen = false;
				vm.saveEntityValidationOpen = false;
				vm.completeValidationOpen = false;

				if (!isValid) {
					vm.validationMessage = message;
					// HACK: Need to set timeout for message to propogate before triggering tooltip show
					setTimeout(function () {
						vm.dismissModalValidationOpen = true;
					}, 1);
					return;
				}
				vm.validationMessage = '';
				//console.log(vm.currentRow);
				var selectedEntity = vm.selectedEntity;

				if (!selectedEntity) {
					selectedEntity = {
						entityID: vm.currentRow.intakeDocumentInfo.intakeItem.entityID,
						entityTypeID: vm.currentRow.intakeDocumentInfo.intakeItem.entityTypeID
					}
				}

				var dataEntryJSON = null;
				if (vm.selectedEntity && vm.dataEntryJSONHandler && vm.dataEntryJSONHandler.retrieveDataEntryJSON) {
					dataEntryJSON = vm.dataEntryJSONHandler.retrieveDataEntryJSON();
				}

				var exceptionData = {
					selectedRow: vm.currentRow,
					selectedEntity: selectedEntity,
					dataEntryJSON: dataEntryJSON,
					newDocumentTypeID: newDocumentTypeID,
					entityDetails: vm.entityDetails
				};

				var modalInstance = $uibModal.open({
					templateUrl: 'app/Processing/Documents/Modals/_documentProcessingException.html',
					controller: 'DocumentProcessingExceptionModalController',
					resolve: {
						exceptionData: exceptionData,
						searchTimestamp: vm.searchTimestamp,
						optionalArgs: {isTaxBill: vm.currentRow.f9003 === 'Tax Bill'},
                        instanceId: vm.currentRow.intakeDocumentInfo.intakeBatch.instanceId
					},
					windowClass: 'show',
					backdropClass: 'show'
				});

				modalInstance.result.then(function (actionID) {
                    if (actionID === DocumentExceptionActions.CLEAR_EXCEPTION) {
                        vm.currentRow.intakeDocumentInfo.intakeItem.exceptionStatusID = undefined;
                    }
                    else {
						if(actionID == DocumentExceptionActions.REQUEST_TAX_RATE_SETUPS) {
							vm.currentRow.intakeDocumentInfo.intakeItem.exceptionStatusID = ExceptionStatusIDs.RATES_REQUESTED;
						}
                        vm.openNextDocument();
                    }
                });
			});
		};

		vm.downloadAttachment = function () {
			var docInfo = vm.currentRow.intakeDocumentInfo;
			attachmentService.downloadAttachmentByGuid(docInfo.attachmentID, docInfo.attachmentMetaData, docInfo.fileExtension);
		};

		vm.loadQuickSearch = function () {
			vm.entityIsMapped = false;
			vm.associatedEntityMissing = false;
			vm.associatedEntityChanged = false;
			vm.showCriteria = true;
			vm.setDirty(true);

			vm.entityDetails = undefined;
			vm.selectedEntity = undefined;
			vm.currentRow.intakeDocumentInfo.intakeItem.entryJSON = null;
		}

		vm.saveEntityDetails = function () {
			checkValidationFunction(vm.validationHandler.validate)(function (isValid, message) {
				vm.dismissModalValidationOpen = false;
				vm.saveEntityValidationOpen = false;
				vm.completeValidationOpen = false;

				if (!isValid) {
					vm.validationMessage = message;
					// HACK: Need to set timeout for message to propogate before triggering tooltip show
					setTimeout(function () {
						vm.saveEntityValidationOpen = true;
					}, 1);
					return;
				}
				vm.validationMessage = '';
				var documentIntakeID = vm.currentRow.intakeDocumentInfo.intakeItem.intakeItemID;
				var taskID = vm.currentRow.n10908;
				var dataEntryJSON = null;
				if (vm.selectedEntity && vm.dataEntryJSONHandler && vm.dataEntryJSONHandler.retrieveDataEntryJSON) {
					dataEntryJSON = vm.dataEntryJSONHandler.retrieveDataEntryJSON();
				}

				checkForChangesInParcel();

				processingService.saveItemEntity(documentIntakeID, taskID, vm.selectedEntity, newDocumentTypeID, dataEntryJSON, vm.currentRow.intakeDocumentInfo.intakeItem.rowVersion, vm.currentRow.intakeDocumentInfo.parcelChanges)
					.then(function (result) {
                        vm.currentRow.intakeDocumentInfo.intakeItem.rowVersion = result.intakeItemRowVersion;
						// The user may have navigated away after clicking save; we don't want to change
						// the dirty state in that case
						if (taskID === vm.currentRow.n10908) {
							vm.setDirty(false);
						}
						toastr.success('Save successful', 'Entity Details have been saved');
					});
			});
		}

		vm.completeTask = function () {

			vm.validationHandler.validate(function (isValid, message) {
				vm.dismissModalValidationOpen = false;
				vm.saveEntityValidationOpen = false;
				vm.completeValidationOpen = false;

				if (!isValid) {
					vm.validationMessage = message;
					// HACK: Need to set timeout for message to propogate before triggering tooltip show
					setTimeout(function () {
						vm.completeValidationOpen = true;
					}, 1);
					return;
				}
				vm.validationMessage = '';
				var documentIntakeID = vm.currentRow.intakeDocumentInfo.intakeItem.intakeItemID;
				var taskID = vm.currentRow.n10908;
				var selectedEntity = vm.selectedEntity;

				if (!selectedEntity) {
					selectedEntity = {
						entityID: vm.currentRow.intakeDocumentInfo.intakeItem.entityID,
						entityTypeID: vm.currentRow.intakeDocumentInfo.intakeItem.entityTypeID,
					}
				}

				var dataEntryJSON = null;
				if (vm.selectedEntity && vm.dataEntryJSONHandler && vm.dataEntryJSONHandler.retrieveDataEntryJSON) {
					dataEntryJSON = vm.dataEntryJSONHandler.retrieveDataEntryJSON();
				}

				checkForChangesInParcel();

				vm.disableEntryButtons = true;
				processingService.completeItem(documentIntakeID, taskID, selectedEntity, newDocumentTypeID, dataEntryJSON, vm.currentRow.intakeDocumentInfo.intakeItem.rowVersion, vm.currentRow.intakeDocumentInfo.parcelChanges)
					.then(function () {
						toastr.success('Task completed successfully', 'Success');
						completedItemCount++;
						vm.openNextDocument();
					})
					.finally(function() {
						vm.disableEntryButtons = false;
					});
			}, true);
		}

		vm.documentTypeChanged = function () {
			newDocumentTypeID = vm.currentRow.intakeDocumentInfo.attachmentType.attachmentTypeID;
			var documentType = _.find(vm.documentTypeList, { attachmentTypeID: vm.currentRow.intakeDocumentInfo.attachmentType.attachmentTypeID });

			vm.resultTypes = processingService.getResultTypesByDocumentType(documentType);

			if (!_.some(vm.resultTypes, { type: vm.criteria.resultType }) && vm.resultTypes.length) {
				var foundResultType = _.find(vm.resultTypes, {id: documentType.defaultResultsEntityTypeID});

				vm.criteria.resultType = foundResultType ? foundResultType.type : vm.resultTypes[0].type;
			}
		}

		vm.getCommentsModalData = function () {
			return {
				entityTypeID: EntityTypeIDs.INTAKE_ITEM,
				entityID: vm.currentRow.intakeDocumentInfo.intakeItem.intakeItemID,
				description: vm.getIntakeBatchItemNumber(),
				canEdit: true
			}
		}

		vm.getIntakeBatchItemNumber = function () {
			return 'Intake Item: ' + vm.currentRow.intakeDocumentInfo.intakeBatch.batchNumber + "#" + vm.currentRow.intakeDocumentInfo.intakeItem.itemNumber +
				(vm.currentRow.intakeDocumentInfo.intakeItem.duplicateNumber ? ("_" + vm.currentRow.intakeDocumentInfo.intakeItem.duplicateNumber) : "");
		}

		vm.launchDuplicateModal = function () {
			var duplicateItem = {
				duplicateCount: 1,
				intakeItemID: vm.currentRow.intakeDocumentInfo.intakeItem.intakeItemID,
				attachmentTypeID: vm.currentRow.intakeDocumentInfo.attachmentType.attachmentTypeID
			};
			processingService.launchDuplicateIntakeItemModal(duplicateItem)
				.then(function (newItems) {
					if (newItems) {
						toastr.success('Duplicate successfully created');

                        // WK-5083 Don't show duplicate items if we're currently on a QC task
						if (!vm.isQCItem) {
						    var newIndex = vm.currentRowIndex + 1;
						    _.forEach(newItems, function (item) {
						        vm.rows.splice(newIndex, 0, item);
						        newIndex++;
						    });

						    getRowData(vm.currentRowIndex + 1);
						}
					}
				}, function () {
					toastr.error('Failed to duplicate, please try again');
				});
		};

		vm.billRevisionChanged = function (revision) {
			var fmv = new Decimal(0);
			var av = new Decimal(0);
			_.forEach(revision.annualGridComponents, function (component) {
				if (component.assessmentComponentTypeID !== 3) {
					fmv = fmv.plus(component.fairMarketValue);
					av = av.plus(component.assessedValue);
				}
			});
			vm.revisionInfo = {
				totalFMV: fmv.toNumber(),
				totalAssessedValue: av.toNumber(),
				isProjected: revision.status === 1 ? false : true
			};

			vm.loadingRevisionData = false;
		}

		function logPinTip(row) {
			if (row && row.n10908) {
				console.log(
					"To pin this task to document processing, paste the following at the end of your url and refresh the page:\n" +
					"?taskID=" + row.n10908);
			}
		}

		function notifyChildWindow(popUpRef, intakeDocumentInfo) {
			logPinTip(vm.currentRow);
			if (popUpRef.isOpen()) {
				var popUpData = {
					attachmentInfo: {
						attachmentID: intakeDocumentInfo.attachmentID,
						fileName: intakeDocumentInfo.attachmentMetaData,
						fileExtension: intakeDocumentInfo.fileExtension
					}
				};
				popUpRef.publish(popUpService.Topics.Attachment.AttachmentChanged, popUpData);
			}
		}

		function getEntityTree(entityTypeId, entityId) {
			vm.selectedEntity = {
				entityId: entityId,
				entityTypeId: entityTypeId
			};

			vm.disableEntryButtons = true;
			return processingService.getEntityTree(entityTypeId, entityId, true)
				.then(function (response) {
					vm.entityDetails = response;

					if (vm.entityDetails.extendedData.propertyTypeID) {
						vm.entityDetails.extendedData.propertyTypeAbbr = _.find(PropertyTypes, { propertyTypeID: vm.entityDetails.extendedData.propertyTypeID }).propTypeAbbr;
					}

					vm.entityIsMapped = true;
					vm.associatedEntityMissing = false;

					cleanParcelData = null;
					vm.editModeEntityPanel = false;
				})
				.finally(function() {
					vm.disableEntryButtons = false;
				});
		}

		function activate() {
			getDocumentTypeList();

			vm.currentRow = null;
			vm.currentRowIndex = 0;
			vm.entityIsMapped = false;
			vm.associatedEntityMissing = false;
			vm.associatedEntityChanged = false;
			vm.selectedEntity = undefined;
			vm.entityDetails = undefined;
			vm.completeButtonLabel = '';
            vm.hasIdpFeature = featureFlagsService.featureFlags.enableIdp;
            vm.criteria = processingService.getCriteria();

			vm.resultTypes = processingService.getResultTypes();

			newDocumentTypeID = null;
			vm.setDirty(false);

			vm.rows = $stateParams.selectedRows;
            vm.searchTimestamp = $stateParams.searchTimestamp;

            // console.log('vm.searchTimestamp', vm.searchTimestamp);

			// Hack for displaying a single task ID by URL
			if ($state.params.taskID) {
			    vm.initializing = true;
				initializeByTaskID($state.params.taskID).then(function () {
					initializeRows();
                    vm.initializing = false;
				});
			}
			else if ($state.params.intakeItemID) {
			    vm.initializing = true;
				initializeByIntakeItemID($state.params.intakeItemID).then(function () {
					initializeRows();
                    vm.initializing = false;
				});
			}
			else {
			    initializeRows();
			    logPinTip(vm.rows[0]);
			}

			$scope.$on('processingQuickSearchClicked', function (event, result) {
				vm.setDirty(true);
				getEntityTree(result.entityTypeId, result.entityId);
			})
		}

		function initializeRows() {
			if (vm.rows.length > 0) {
				getRowData(0, true).then(function () {
                    // refresh pop-up window if it is available
                    if (vm.rows[vm.currentRowIndex] && vm.rows[vm.currentRowIndex].intakeDocumentInfo) {
                        var globalAttachmentsPopUpRef = popUpService.getByAliasGroup(popUpService.StaticAliases.Attachment)[0];
                        globalAttachmentsPopUpRef && notifyChildWindow(globalAttachmentsPopUpRef, vm.rows[vm.currentRowIndex].intakeDocumentInfo);
                    }

					switch (vm.rows[0].n10902) {
						case 31:
							vm.completeButtonLabel = 'Complete Data Entry';
							vm.isQCItem = false;
							break;
						case 32:
							vm.completeButtonLabel = 'Complete QC';
							vm.isQCItem = true;
							break;
					}

                    // If it has IDP values, map them to the search criteria
                    if (vm.rows[0].intakeDocumentInfo.idpResults?.length) {
                        vm.criteria = processingService.getCriteriaWithIdp(vm.rows[0].intakeDocumentInfo.idpResults);
                    }

					vm.entityIsMapped = vm.rows[0].intakeDocumentInfo.intakeItem.entityID !== null;
					if (vm.entityIsMapped) {
					    vm.associatedEntityMissing = vm.rows[0].intakeDocumentInfo.associatedEntityMissing;
					    vm.associatedEntityChanged = vm.rows[0].intakeDocumentInfo.associatedEntityChanged;
						var entityId = vm.rows[0].intakeDocumentInfo.intakeItem.entityID;
						var entityTypeId = vm.rows[0].intakeDocumentInfo.intakeItem.entityTypeID;
						vm.documentTypeChanged();

						if (!vm.associatedEntityMissing) {
						    getEntityTree(entityTypeId, entityId);
						}
					}
				});
			}
		}

		function initializeByTaskID(taskID) {
		    return documentService.getTaskInfoByTaskID(taskID).then(function (taskInfo) {
		        vm.rows = [taskInfo];
		    });
		}

		function initializeByIntakeItemID(intakeItemID) {
		    return documentService.getTaskInfo(intakeItemID).then(function (taskInfo) {
		        vm.rows = [taskInfo];
		    });
		}

		function getRowData(rowIndex, displayNow) {
			if (vm.rows.length <= rowIndex) {
				return $q.resolve(null);
			} else {
				//TODO: Maybe check for the intakeDocumentInfo object first
				return documentService.getDocumentInfoByTaskId(vm.rows[rowIndex].n10908)
					.then(function (intakeDocumentInfo) {
						vm.rows[rowIndex].intakeDocumentInfo = intakeDocumentInfo;
						vm.rows[rowIndex].fileError = false;

						return loadAttachment(rowIndex, displayNow);
					});

			}
		}

		function loadAttachment(rowIndex, displayNow) {

			// console.log('getRowData - loading attachment ', rowIndex);

			var fileInfo = attachmentService.getMimeTypeByFileExtension(vm.rows[rowIndex].intakeDocumentInfo.fileExtension);
			if (fileInfo.previewSupported) {
				return documentService.getDocumentBody(vm.rows[rowIndex].intakeDocumentInfo.attachmentID)
					.then(function (response) {
						var fileName = attachmentService.getFileNameFromHttpResponse(response, true);
						var fileExtension = fileName.split('.')[1].substring(0, 3);
						var attachmentBlob = new Blob([response.data], { type: fileExtension });
						var attachmentBlobUrl = URL.createObjectURL(attachmentBlob);
						//var fileInfo = attachmentService.getMimeTypeByFileExtension(fileExtension);

						//// console.log('fileInfo', fileInfo);
						// console.log('getRowData - attachment loaded ', rowIndex);

						if (fileInfo.previewSupported) {
							vm.rows[rowIndex].intakeDocumentInfo.attachmentUrl = $sce.trustAsResourceUrl(attachmentBlobUrl);
							//// console.log('vm.attachmentUrl', vm.attachmentUrl);
							vm.rows[rowIndex].intakeDocumentInfo.fileInfo = fileInfo;
							//// console.log('vm.fileInfo', vm.fileInfo);
						}

						if (displayNow == true) {
							// console.log('getRowData - setting this row as current one ', rowIndex);
							vm.currentRow = vm.rows[rowIndex];
						}

						//// console.log(vm.currentRow)

						// we might want to load next row - if there is one
						//
						if (rowIndex == 0 && vm.rows.length >= 2) {
							// console.log('getRowData - loading second row right away ');
							if (!vm.rows[1].intakeDocumentInfo) {
								getRowData(1, false);
							}
						}
					}).catch(function () {
						vm.rows[rowIndex].fileError = true;
					}).finally(function () {
						if (displayNow == true) {
							vm.currentRow = vm.rows[rowIndex];
						}
					});
			} else {
				vm.rows[rowIndex].intakeDocumentInfo.attachmentUrl = null;
				vm.rows[rowIndex].intakeDocumentInfo.fileInfo = fileInfo;
				if (displayNow == true) {
					vm.currentRow = vm.rows[rowIndex];
				}

				// we might want to load next row - if there is one
				//
				if (rowIndex == 0 && vm.rows.length >= 2) {
					// console.log('getRowData - loading second row right away ');
					if (!vm.rows[1].intakeDocumentInfo) {
						getRowData(1, false);
					}
				}

				return $q.resolve();
			}
		}

		function getDocumentTypeList() {
			attachmentService.getAttachmentTypes().then(function (results) {
				vm.documentTypeList = results;
			});
		}

		vm.showLegalDescriptionDialog = function() {

			var legalDescriptionDialogModalInstance = $uibModal.open({
				keyboard: true,
				modalFade: true,
				size: 'lg',
				windowClass: 'super-max-modal-size show',
				templateUrl: 'app/Entity/Parcel/Info/_legalDescription.Modal.html',
				controller: 'Parcel.LegalDescription.Modal.Controller',
				controllerAs: 'vm',
				backdropClass: 'show',
				resolve: {
					dialogData: function () {
						return {
							acctNum: vm.entityDetails.extendedData.parcelAcctNum,
							legalDescription: vm.entityDetails.extendedData.parcelLegalDescription,
							editMode: false,
						};
					}
				}
			});
		}

		vm.isSubEntity = function() {
			if(!vm.selectedEntity) {
				return;
			}

			return _.includes([EntityTypeIDs.TAX_BILL, EntityTypeIDs.ASSESSMENT, EntityTypeIDs.APPEAL, EntityTypeIDs.FILING, EntityTypeIDs.PAYMENT], vm.selectedEntity.entityTypeId)
		}

		vm.editEntityPanel = function() {
			// If parcel changes object from API, then save it off so it can be reverted to on cancel
			if(vm.currentRow.intakeDocumentInfo.parcelChanges) {
				cleanParcelData = _.cloneDeep(vm.currentRow.intakeDocumentInfo.parcelChanges);
			} else {
				// If no parcel changes, then init it with extended data.
				var parcelChangesFields = ['assesseeName', 'collectorAcctNum', 'parcelCollectorID', 'parcelRowVersion', 'parcelAddressRowVersion', 'parcelCollectorRowVersion'];

				vm.currentRow.intakeDocumentInfo.parcelChanges = _.pick(vm.entityDetails.extendedData, parcelChangesFields);
				vm.currentRow.intakeDocumentInfo.parcelChanges.parcelAddress = _.pick(vm.entityDetails.extendedData.parcelAddress, ['address1', 'address2', 'city', 'stateID', 'zip']);
				vm.currentRow.intakeDocumentInfo.parcelChanges.parcelAddress.abbr = vm.entityDetails.extendedData.parcelState.abbr;
			}

            vm.setDirty(true);
			vm.editModeEntityPanel = true;
		}

		vm.cancelChanges = function() {
			vm.currentRow.intakeDocumentInfo.parcelChanges =  _.cloneDeep(cleanParcelData);

			cleanParcelData = null;

			vm.editModeEntityPanel = false;
		}

		vm.isOverridden = function(field, isAddress) {
			if(!vm.currentRow.intakeDocumentInfo.parcelChanges) {
				return false;
			}

            if (field === 'taxSetup') {
                return !!vm.currentRow.intakeDocumentInfo.parcelChanges.newTaxSetup;
            } else if(isAddress) {
				return !_.isEqual(vm.currentRow.intakeDocumentInfo.parcelChanges.parcelAddress[field], vm.entityDetails.extendedData.parcelAddress[field]);
			} else {
				return !_.isEqual(vm.currentRow.intakeDocumentInfo.parcelChanges[field], vm.entityDetails.extendedData[field]);
			}
        }

        vm.openTaxSetupModal = function () {
            var newTaxSetup = null;
            if (vm.currentRow.intakeDocumentInfo.parcelChanges) {
                newTaxSetup = vm.currentRow.intakeDocumentInfo.parcelChanges.newTaxSetup;
            }
            taxRateSetupModalLaunchService.openDocumentProcessingParcelTaxSetupModal(
                vm.entityDetails.parcel.entityId, {
                    parcelCollectorID: vm.entityDetails.extendedData.parcelCollectorID,
                    rowVersion: vm.entityDetails.extendedData.parcelCollectorRowVersion,
                    collectorID: vm.entityDetails.collector.collectorID
                },
                newTaxSetup,
                vm.entityDetails.collector.text,
                vm.entityDetails.annualYear.text,
                !vm.editModeEntityPanel
            ).subscribe({ next: function (modalResult) {
                vm.currentRow.intakeDocumentInfo.parcelChanges.newTaxSetup = modalResult;
            }});
		}

		function checkForChangesInParcel() {
			if(!vm.isOverridden('assesseeName')
				&& !vm.isOverridden('address1', true)
				&& !vm.isOverridden('address2', true)
				&& !vm.isOverridden('city', true)
				&& !vm.isOverridden('zip', true)
                && !vm.isOverridden('collectorAcctNum')
                && !vm.isOverridden('taxSetup')) {
					vm.currentRow.intakeDocumentInfo.parcelChanges = null;
			}
		}
	}
})();
