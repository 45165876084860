import { Component, Input, OnInit } from '@angular/core';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { BillViewModel } from '../../../../Annual-Details/Taxes/bill-cluster.service';
import { WeissmanModalService } from '../../../../Compliance/WeissmanModalService';
import { IdpDetailModalComponent, IdpDetailModalParams } from './Detail-Modal/idpDetailModal.component';

@Component({
    selector: 'idp-confidence-panel',
    templateUrl: './idpConfidencePanel.component.html',
    styleUrls: ['./idpConfidencePanel.component.scss']
})
export class IdpConfidencePanelComponent implements OnInit {
    constructor(private readonly _weissmanModalService: WeissmanModalService) {
    }

    @Input() currentDocument: Core.IntakeItemInfo;
    @Input() billDetail: BillViewModel;

    confidenceLevel: 'Low' | 'Medium' = 'Low';

    ngOnInit(): void {
        this.confidenceLevel = this.currentDocument.idpConfidence ? 'Medium' : 'Low';
    }

    async seeDetails(): Promise<void> {
        const params: IdpDetailModalParams = {
            confidence: this.confidenceLevel,
            idpResult: this.currentDocument.ocrResults,
            billDetail: this.billDetail
        };

        const modalOptions: ModalOptions = {
            class: 'modal-lg',
            initialState: { params },
            backdrop: false,
            ignoreBackdropClick: true,
            keyboard: false
        };
        await this._weissmanModalService.showAsync(IdpDetailModalComponent, params, 'modal-lg', modalOptions);
    }
}
