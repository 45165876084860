<div class="d-flex align-items-center">
    <input type="text"
           class="form-control"
           tabindex="0"
           [ngClass]="inputClass"
           [ngModel]="value"
           (ngModelChange)="inputValueChanged($event)"
           [wsMask]="inputMask">
    <div *ngIf="idpResults?.length"
         class="btn-group ms-2"
         dropdown
         container="body"
         [(isOpen)]="showIdpMenu">
        <button type="button"
                class="flat-button icon-button primary-button fa fa-plus m-0"
                title="Add"
                tabindex="0"
                dropdownToggle
                (mouseenter)="showIdpMenu = true; idpButtonHovered = true"
                (mouseleave)="buttonHoverOut()"
                (focusin)="menuFocusIn()"
                (focusout)="menuFocusOut()"
                (keydown)="keyedSelection($event)"
                (click)="setSingleIdpValue()">
        </button>
        <ul *dropdownMenu #testEl class="dropdown-menu" role="menu"
            (mouseenter)="showIdpMenu = true; idpMenuHovered = true;"
            (mouseleave)="menuHoverOut()">
            <li role="menuitem" *ngFor="let field of idpResults; let i = index;">
                <div class="dropdown-item clickable d-flex flex-column"
                     [id]="'idpValue-' + i"
                     [ngClass]="{ 'active': field.name === value }"
                     (click)="inputValueChanged(field.name)">
                    <span *ngIf="field.subCategory">{{field.subCategory}}</span>
                    <a>{{ field.name }}</a>
                </div>
            </li>
        </ul>
    </div>
</div>

