import { Injectable } from '@angular/core';
import { QuickCriteriaFilter } from './quickCriteria.model';

import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class QuickFiltersService {
    getAll(): QuickCriteriaFilter[] {
        return this._getFilters();
    }

    getActive(): QuickCriteriaFilter[] {
        return _.filter(this._getFilters(), 'default');
    }

    getDocumentActive(): QuickCriteriaFilter[] {
        return _.union(_.filter(this._getFilters(), 'defaultDocument'), this._getExtraDocumentFilters());
    }

    getInactive(): QuickCriteriaFilter[] {
        return _.reject(this._getFilters(), 'default');
    }

    getDocumentInactive(): QuickCriteriaFilter[] {
        return _.reject(this._getFilters(), 'defaultDocument');
    }

    private _getExtraDocumentFilters(): QuickCriteriaFilter[] {
        return [{
            idpFilterType: 'f1402',
            name: 'Tax Collector',
            field: 'taxCollector',
            associatedWith: 'taxbill',
            default: false,
            defaultDocument: false,
            dropdown: false,
            sequenceNum: 16
        }, {
            idpFilterType: 'f901',
            name: 'Tax Year',
            field: 'taxYear',
            associatedWith: 'taxbill',
            default: false,
            defaultDocument: false,
            dropdown: false,
            sequenceNum: 17
        }];
    }

    private _getFilters(): QuickCriteriaFilter[] {
        return [{
            name: 'Company Name',
            field: 'companyName',
            associatedWith: 'company',
            default: true,
            defaultDocument: true,
            dropdown: false,
            sequenceNum: 1
        }, {
            name: 'Site Name',
            field: 'siteName',
            associatedWith: 'site',
            default: true,
            defaultDocument: true,
            dropdown: false,
            sequenceNum: 2
        }, {
            idpFilterType: 'f601',
            name: 'Parcel Acct Num',
            field: 'parcelAcctNum',
            associatedWith: 'parcel',
            default: true,
            defaultDocument: true,
            dropdown: false,
            sequenceNum: 9
        }, {
            name: 'Site Address',
            field: 'siteAddress',
            associatedWith: 'site',
            dropdown: false,
            default: false,
            defaultDocument: false,
            sequenceNum: 4,
            resultField: ''
        }, {
            name: 'Site City',
            field: 'siteCity',
            associatedWith: 'site',
            dropdown: false,
            default: false,
            defaultDocument: false,
            sequenceNum: 5,
            resultField: 'siteCity'
        }, {
            name: 'Parcel Prop Type',
            field: 'parcelPropType',
            associatedWith: 'parcel',
            dropdown: true,
            default: false,
            defaultDocument: false,
            sequenceNum: 10,
            resultField: 'propertyType'
        }, {
            name: 'Site State Abbr',
            field: 'siteStateAbbr',
            associatedWith: 'site',
            dropdown: true,
            default: false,
            defaultDocument: false,
            sequenceNum: 6,
            resultField: 'siteState'
        }, {
            name: 'Parcel Address',
            field: 'parcelAddress',
            associatedWith: 'parcel',
            dropdown: false,
            default: false,
            defaultDocument: false,
            sequenceNum: 11,
            resultField: ''
        }, {
            name: 'Parcel Assessee',
            field: 'parcelAssessee',
            associatedWith: 'parcel',
            dropdown: false,
            default: false,
            defaultDocument: false,
            sequenceNum: 14,
            resultField: 'assesseeName'
        }, {
            name: 'Parcel Assessor',
            field: 'parcelAssessor',
            associatedWith: 'parcel',
            dropdown: false,
            default: false,
            defaultDocument: true,
            sequenceNum: 12,
            resultField: ''
        }, {
            name: 'Parcel Characteristic',
            field: 'parcelDescriptor',
            associatedWith: 'parcel',
            dropdown: false,
            default: false,
            defaultDocument: false,
            sequenceNum: 15
        }, {
            name: 'Parcel City',
            field: 'parcelCity',
            associatedWith: 'parcel',
            dropdown: false,
            default: false,
            defaultDocument: false,
            sequenceNum: 13,
            resultField: 'parcelCity'
        }, {
            name: 'Site Assessor',
            field: 'siteAssessor',
            associatedWith: 'site',
            dropdown: false,
            default: false,
            defaultDocument: false,
            sequenceNum: 7,
            resultField: ''
        }, {
            name: 'Site Characteristic',
            field: 'siteDescriptor',
            associatedWith: 'site',
            dropdown: false,
            default: false,
            defaultDocument: false,
            sequenceNum: 8
        }, {
            name: 'Site Number',
            field: 'siteNumber',
            associatedWith: 'site',
            dropdown: false,
            default: false,
            defaultDocument: false,
            sequenceNum: 3,
            resultField: 'propertyNumber'
        }];
    }
}
