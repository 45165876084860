import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BillViewModel } from '../../../../../Annual-Details/Taxes/bill-cluster.service';
import { Bill } from '../../../../../Annual-Details/Taxes/bill.model';
import { IWeissmanModalComponent } from '../../../../../Compliance/WeissmanModalService';
import { IdpCard } from './Card/idpDetailCard.component';

export interface IdpDetailModalParams {
    confidence: string;
    idpResult: Core.TaxBillPushMessageDto;
    billDetail: BillViewModel;
}

@Component({
    selector: 'idp-detail-modal',
    templateUrl: './idpDetailModal.component.html',
    styleUrls: ['./idpDetailModal.component.scss']
})
export class IdpDetailModalComponent implements OnInit, AfterViewInit, IWeissmanModalComponent<IdpDetailModalParams, void> {
    constructor(private readonly _bsModalRef: BsModalRef) {
    }

    params: IdpDetailModalParams;
    result: void;

    billCard: IdpCard;
    payments: IdpCard[];

    // todo update the value keys once we know the correct values
    private _cardValues = [
        { name: 'Base Pay Amt', value: this._getBasePayAmt.bind(this), type: 'currency' },
        { name: 'Discount Amt', value: this._getDiscountAmt.bind(this), type: 'currency' },
        { name: 'Interest', value: this._getInterestAmt.bind(this), type: 'currency' },
        { name: 'Penalty', value: this._getPenaltyAmt.bind(this), type: 'currency' }
    ];

    get title(): string {
        return `Detected Values: ${this.params?.confidence} Confidence`;
    }

    ngOnInit(): void {
        console.log(this.params);
        const bill = this.params.billDetail.model[0];
        this.billCard = {
            title: 'Bill',
            total: { name: 'Bill Amt', value: this.params.idpResult.totalAmount },
            details: [
                { name: 'Assoc. Revision', value: this._getAssocRevisionDesc(bill), type: 'string' },
                { name: 'Payment Option', value: this._getBillPaymentOptionName(bill), type: 'string' }
            ]
        };

        this.payments = this.params.idpResult.installments.map((x, i: number) => {
            return {
                title: `Payment ${i} of ${this.params.idpResult.installments.length}`,
                total: { name: 'Payment Amt', value: x.details[0]?.amount },
                details: this._cardValues.map(y => {
                    return { name: y.name, value: y.value(x), type: y.type };
                })
            };
        });
    }

    ngAfterViewInit(): void {
        const modalContainer = document.querySelector('modal-container');
        if (modalContainer) {
            modalContainer.classList.add('no-modal-backdrop');
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    private _getBasePayAmt(installment: Core.InstallmentMessageItem): number {
        return installment.details[0]?.amount;
    }

    private _getDiscountAmt(installment: Core.InstallmentMessageItem): number {
        return installment.details[0]?.discount[0]?.discountAmount;
    }

    private _getInterestAmt(installment: Core.InstallmentMessageItem): number {
        return installment.details[0]?.interest[0]?.interestAmount;
    }

    private _getPenaltyAmt(installment: Core.InstallmentMessageItem): number {
        return installment.details[0]?.penalty[0].penaltyAmount;
    }

    private _getAssocRevisionDesc(bill: Bill): string {
        const yearRevision = this.params.billDetail.yearRevisions.find(x => x.annualAssessmentID === bill.annualAssessmentID);

        return yearRevision?.revisionDesc || '';
    }

    private _getBillPaymentOptionName(bill: Bill): string {
        if (!bill.collectorPaymentOptionID || !bill.collectorPaymentOptions) {
            return '';
        } else if (bill.collectorPaymentOptions.length === 0) {
            return bill.collectorPaymentOption.name || 'N/A';
        }

        const option = bill.collectorPaymentOptions.find(x => x.collectorPaymentOptionID === bill.collectorPaymentOptionID);

        return option?.name || 'N/A';
    }
}
