<div class="overflow-auto">
    <table style="width: 100%; margin-bottom: 10px;">
        <tr *ngIf="isDocumentProcessing && currentDocument?.clientInstanceId">
            <td>Client Instance</td>
            <td>{{ currentDocument.clientInstanceName }}</td>
        </tr>
        <tr class="search-filter delete-hover"
            *ngFor="let filter of criteria.activeFilters | sortBy: 'asc':'sequenceNum'; let i = index">
            <td class="text-start" style="width: 140px;">
                {{ filter.name }}
            </td>
            <td>
                <auto-populate-input *ngIf="!filter.dropdown && filter.field !== 'taxYear'"
                                     [name]="'search-filter-' + i"
                                     [inputClass]="'search-filter-' + i"
                                     [(ngModel)]="filter.text"
                                     [idpResults]="filter.idpResults"
                                     (ngModelChange)="changeResultType()">
                </auto-populate-input>
                <auto-populate-input *ngIf="filter.field === 'taxYear'"
                                     [name]="'search-filter-' + i"
                                     [inputClass]="'search-filter-' + i"
                                     [isDateInput]="true"
                                     [(ngModel)]="filter.text"
                                     [idpResults]="filter.idpResults"
                                     (ngModelChange)="changeResultType()">
                </auto-populate-input>
                <state-dropdown *ngIf="filter.field === 'siteStateAbbr'"
                                [(stateAbbr)]="filter.text"
                                (stateAbbrChange)="changeResultType()"
                                [canSearch]="true"
                                [ngClass]="'search-filter-' + i">
                </state-dropdown>
                <ws-select *ngIf="filter.field === 'parcelPropType'"
                           [ngClass]="'search-filter-' + i"
                           [(ngModel)]="filter.text"
                           [canSearch]="true"
                           [includeSearchAfterN]="1"
                           [options]="propertyTypes"
                           valueProperty="propTypeAbbr"
                           labelProperty="propTypeAbbr"
                           (change)="changeResultType()">
                </ws-select>
            </td>
            <td class="align-middle" style="width: 30px;">
                <i class="fa fa-close hidden-hover"
                   aria-label="Remove Filter"
                   aria-hidden="true"
                   helpTooltip
                   [tooltipText]="'Remove'"
                   [position]="'bottom'"
                   (click)="removeFilter(filter, i)" tabindex="-1">
                </i>
            </td>
        </tr>
    </table>
</div>
<div *ngIf="criteria.inactiveFilters?.length !== 0"
     dropdown
     container="body"
     style="margin-left: 22px;"
     (isOpenChange)="addFilterToggled($event)">
    <button type="button"
            class="flat-button icon-button success-button fa fa-plus"
            aria-label="Add Filter"
            title="Add"
            dropdownToggle>
    </button>
    <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li *ngFor="let filter of criteria.inactiveFilters | sortBy: 'asc': 'name'">
            <a href="" tabindex="0" (click)="addFilter(filter)">{{ filter.name }} </a>
        </li>
    </ul>
</div>
<div class="float-end">
    <div style="display: inline-block; margin-right: 10px; vertical-align: middle;">
        Results
    </div>
    <div style="display: inline-block; margin-right: 10px; vertical-align: middle;">
        <select class="form-select form-select-sm" [(ngModel)]="criteria.resultType">
            <option *ngFor="let option of resultTypes | sortBy: 'asc':'id'" [value]="option.type">{{ option.name }}
            </option>
        </select>
    </div>
    <div style="display: inline-block; margin: 0 10px; vertical-align: middle;" class="checkbox">
        <div>
            <label>
                <input type="checkbox" [(ngModel)]="criteria.primaryAcctNumOnly"> Primary Acct Num Only
            </label>
        </div>

    </div>
    <div style="display: inline-block; margin: 0 10px; vertical-align: middle;" class="checkbox">
        <div>
            <label>
                <input type="checkbox" [(ngModel)]="criteria.activeOnly"> Exclude Inactive
            </label>
        </div>
        <div *ngIf="showAnticipated()">
            <label>
                <input type="checkbox" [(ngModel)]="criteria.anticipated"> Anticipated
            </label>
        </div>
    </div>
    <button class="flat-button icon-button primary-button fa fa-search"
            aria-label="Start Search"
            title="Search"
            (click)="search.emit()"
            data-weiss-quicksearch-search>
    </button>
</div>
<div class="clearfix"></div>
